<template>
  <div>
    <!-- Top button bar --> 
    <div class="buttonBar">
      <button v-if="comingFromPage != null" class="back-home-button" @click="backHome">Back</button>
      <div v-if="comingFromPage == null"></div>
      <div></div>
      <!-- <div class="vertical-layout">
        <div>
          <img src="/Images/Warning.png" alt="Feature in Development"/>
        </div>
        <div style="color: #F39200;">Feature in development</div>
      </div>
      <button class="feedbackButton" @click="giveFeedback">Give<br>Feedback</button> -->

      <!-- Only for players --> 
      <div v-if= amIAPlayer>
        <div v-if= isPublicGame class="centerClass">Public</div>
        <div v-else class="centerClass">Private</div>
        <div class="centerDiv2">
          <input class="slider" type="range" v-model="isPublicSliderValue" min="0" max="1" value="0" @input="handleSliderChange">
        </div>
      </div>

      <button class="share-button" @click="copyUrl">
        <img src="/Images/ShareIcon.png" alt="Share">
      </button>
    </div>

    <div v-if= !this.viewAccess name="MainArea">
      <div class="centerDiv2">
        <img :src="'/Images/RestrictedAccess.jpg'"/>
      </div>
    </div>
    <div v-else>
      <!-- START SCREEN -->
      <div v-if="gamedata != null && index < 0" class="grid-container3">
        <div class="grid-item">
          <div>
            <div class="vertical-layout">
              <div>
                <img v-if="!gamedata.PlayersData[0].ArmyName" :src="'/Images/ArmyIcons/NoArmy.png'" 
                      width="100" height="100"/>
                <img v-else :src="'/Images/ArmyIcons/' + gamedata.PlayersData[0].ArmyName + '.png'" 
                      width="100" height="100"/>
              </div>
              <div class="playerName">{{ gamedata.PlayersData[0].PlayerName }}</div>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div>
            <div class="vertical-layout">
              <h1 style="font-size: 3em;">VS</h1>
              <div class="centerText">{{ gamedata.Deployment}}</div>
              <br>
              <div class="centerText">{{ gamedata.Objective}}</div>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div>
            <div class="vertical-layout">
              <div>
                <img v-if="!gamedata.PlayersData[1].ArmyName" :src="'/Images/ArmyIcons/NoArmy.png'" 
                      width="100" height="100"/>
                <img v-else :src="'/Images/ArmyIcons/' + gamedata.PlayersData[1].ArmyName + '.png'" 
                      width="100" height="100"/>
              </div>
              <div class="playerName">{{ gamedata.PlayersData[1].PlayerName }}</div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- END SCREEN -->
      <div v-if="gamedata != null && index == this.items.length-1" class="result">
        <div v-if="gamedata.PlayersData[0].Result == 0 && gamedata.PlayersData[1].Result == 0">
          <h1 style="font-size: 3em;">
          No result reported
          </h1>
        </div>
        <div v-else class="grid-container3">
          <div class="grid-item">
            <div>
              <div class="vertical-layout">
                <div>
                  <img v-if="!gamedata.PlayersData[0].ArmyName" :src="'/Images/ArmyIcons/NoArmy.png'" 
                        width="100" height="100"/>
                  <img v-else :src="'/Images/ArmyIcons/' + gamedata.PlayersData[0].ArmyName + '.png'" 
                        width="100" height="100"/>
                </div>
                <div class="playerName">{{ gamedata.PlayersData[0].PlayerName }}</div>
                <br>
                <div v-if='gamedata.PlayersData[0].Objective == "WON"'>
                   <img :src="'/Images/Objective.png'"/> + {{ gamedata.PlayersData[0].PointDifference }}
                </div>
                <div v-else-if='gamedata.PlayersData[0].Objective == "Draw"'>
                  {{gamedata.PlayersData[0].Objective }} {{ gamedata.PlayersData[0].PointDifference }}
                </div>
                <div v-else-if='gamedata.PlayersData[0].Objective == "Victory"'>
                  {{gamedata.PlayersData[0].Objective }} {{ gamedata.PlayersData[0].PointDifference }}
                </div>
                <div v-else-if='gamedata.PlayersData[0].Objective == "Crushing Victory!"'>
                  {{gamedata.PlayersData[0].Objective }} {{ gamedata.PlayersData[0].PointDifference }}
                </div>
                <div v-else>{{ gamedata.PlayersData[0].PointDifference }}</div>
              </div>
            </div>
          </div>
          <div class="grid-item">
          <div>
            <!-- TOW games report result as 1-1. This div is for 9th age -->
            <div v-if="!(gamedata.PlayersData[0].Result == 1 && gamedata.PlayersData[1].Result == 1)">
              <div class="vertical-layout">
                <h1 style="font-size: 5em;">
                  {{ gamedata.PlayersData[0].Result }} - {{ gamedata.PlayersData[1].Result }}
                </h1>
              </div>
            </div>
          </div>
        </div>
          <div class="grid-item">
            <div>
              <div class="vertical-layout">
                <div>
                  <img v-if="!gamedata.PlayersData[1].ArmyName" :src="'/Images/ArmyIcons/NoArmy.png'" 
                        width="100" height="100"/>
                  <img v-else :src="'/Images/ArmyIcons/' + gamedata.PlayersData[1].ArmyName + '.png'" 
                        width="100" height="100"/>
                </div>
                <div class="playerName">{{ gamedata.PlayersData[1].PlayerName }}</div>
                <br>
                <div v-if='gamedata.PlayersData[1].Objective == "WON"'>
                   <img :src="'/Images/Objective.png'"/> + {{ gamedata.PlayersData[1].PointDifference }}
                </div>
                <div v-else-if='gamedata.PlayersData[1].Objective == "Draw"'>
                  {{gamedata.PlayersData[1].Objective }} {{ gamedata.PlayersData[1].PointDifference }}
                </div>
                <div v-else-if='gamedata.PlayersData[1].Objective == "Victory"'>
                  {{gamedata.PlayersData[1].Objective }} {{ gamedata.PlayersData[1].PointDifference }}
                </div>
                <div v-else-if='gamedata.PlayersData[1].Objective == "Crushing Victory!"'>
                  {{gamedata.PlayersData[1].Objective }} {{ gamedata.PlayersData[1].PointDifference }}
                </div>
                <div v-else>{{ gamedata.PlayersData[1].PointDifference }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <p v-if="gamedata != null && index != this.items.length-1" class="overlay-top-left">{{ turn }}</p>
      <div class="container-main-area">
        <button v-if="index != -1" ref="left" @click="back" class="overlay-button-left">&lt;</button>
        <div v-if="gamedata != null && index == -1" class="grid-container2">
            <div>
              <div class="list" v-for="item in gamedata.PlayersData[0].List">{{ item }}</div>
            </div>
            <div>
              <div class="list" v-for="item in gamedata.PlayersData[1].List">{{ item }}</div>
            </div>
        </div>
        <div v-if="gamedata != null && index == this.items.length-1" class="grid-container2">
            <div>
              <div class="list" v-for="item in gamedata.PlayersData[0].ListEnd">{{ item }}</div>
            </div>
            <div>
              <div class="list" v-for="item in gamedata.PlayersData[1].ListEnd">{{ item }}</div>
            </div>
        </div>
        <div v-if="index >= 0 && index < this.items.length-1" class="centerDiv">
          <img class="screenSizeBattleReportImage" :src="this.dataAddress + '/GameReports/' + gameid + '/' + items[index]"/>
        </div>
        <button v-if="this.items != null && index < this.items.length-1" ref="right" @click="next" class="overlay-button-right">></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "battlereport",
  data() {
    return {
      gameid: null,
      items: null,
      index: -1,
      gamedata: null,
      turn: null,
      comingFromPage: null,
      viewAccess: false,
      isPublicGame: false,
      isPublicSliderValue: 1,
      amIAPlayer: false
    };
  },

  computed: {
    dataAddress() {
      return this.$store.state.dataAddress
    }
  },

  mounted() {
    this.getFiles();
    this.getGameData();
    document.addEventListener('keydown', this.handleKeyDown);
    if (this.gameid != null)
    {
      this.hasViewAccess();
    }
    // Auto refresh
    const minutes = 5;
    setInterval(() => {
        location.reload();
    }, 1000 * 60 * minutes); // reload every X minutes
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // Removed user authentication for battle reports
      // if (vm.$store.state.email == null) {
      //   vm.$router.push({ name: "login" });
      // }
      vm.gameid = to.query.id;
      vm.comingFromPage = from.name;
    });
  },

  methods: {
     async getFiles() {
      if (this.gameid == null) { return; }
      let res = await fetch(`/GetPlayedGameFiles/` + this.gameid, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      let response = await res.json();
      this.items = response;
    },

    async getGameData() {
      if (this.gameid == null) { return; }
      let res = await fetch(`/GetGameData/` + this.gameid, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) 
      {
        this.noGameData();
        return;
      }

      let response = await res.json();
      this.gamedata = response
    },

    async hasViewAccess() {
      if (this.gameid == null) { return; }
      let res = await fetch(`/IsGamePrivate/` + this.gameid, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      let isPrivate = await res.json();
      if (!isPrivate)
      {
        this.isPublicGame = true;
        this.isPublicSliderValue = 0;
        this.viewAccess = true;
      }

      res = await fetch(`/AmIAPlayer/` + this.gameid, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      let amIAPlayer = await res.json();
      this.amIAPlayer = amIAPlayer;
      if (!this.viewAccess)
      {
        this.viewAccess = amIAPlayer;
      }
    },

    async handleSliderChange()
    {
      this.isPublicGame = (this.isPublicSliderValue == 0);
      let isPrivate = !this.isPublicGame;
      let gameid = this.gameid;
      try {
        let res = await fetch("/SetPlayedGameIsPrivate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isPrivate, gameid }),
        credentials: 'include',
      });

        if (res.ok) {
          // Data sent successfully
          let changedRows = await res.json();
          if (!changedRows) {
            console.error('Failed to update game report visibility');
            this.isPublicGame = !this.isPublicGame;
          }
        } else {
          // Error sending data
          console.error('Failed to update game report visibility');
          this.isPublicGame = !this.isPublicGame;
        }
      } catch (error) {
        // Network or other error occurred
        console.error('Catch: Failed to update game report visibility:', error);
        this.isPublicGame = !this.isPublicGame;
      }
    },

    noGameData()
    {
      this.gamedata = null;
      this.index = 0;
    },

    getTurnAndPlayer(fileName)
    {
      if (fileName == undefined)
      {
        this.turn = "";
        return;
      }
      if (fileName == "0_Deployment.jpg")
      {
        this.turn = "Deployment";
        return;
      }

      // Remove first 20 and last 4 characters
      var str = fileName.substring(20, fileName.length - 4);
      // Split by underscore
      const arr = str.split('_');

      this.turn = arr[0] + " - " + arr[1];      
    },

    back()
    {
      if (this.index >= 0)
      {
        this.index--;
      }
      this.getTurnAndPlayer(this.items[this.index]);
    },

    next()
    {
      if (this.index <= this.items.length-2) // Exclude GameData.json
      {
        this.index++;
      }
      this.getTurnAndPlayer(this.items[this.index]);
    },

    backHome()
    {
      this.$router.push({ name: "userhome" });
    },

    copyUrl() {
      // Create a new textarea element to hold the URL
      const textarea = document.createElement('textarea');
      //textarea.value = window.location.href;
      textarea.value = window.location.href;
      document.body.appendChild(textarea);
      textarea.select();
      
      // Copy the URL to the clipboard
      document.execCommand('copy');
      
      // Remove the textarea element from the DOM
      document.body.removeChild(textarea);
      
      // Show a success message (optional)
      alert('Battle Report link copied!');
    },

    // giveFeedback()
    // {
    //   const url = 'https://discord.gg/mXJCM2Z2EX';
    //   window.open(url, '_blank');      
    // },

    handleKeyDown(event) {
      // Check if the key code matches the desired key
      if (event.keyCode === 37) { // Enter key
        // Trigger button click event
        this.$refs.left.click();
      }
       if (event.keyCode === 39) { // Enter key
        // Trigger button click event
        this.$refs.right.click();
      }
    }
  },
};
</script>

<style scoped>

.playerName {
  font-size: 2em;
}

.grid-container3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 40px;
  position: relative;
}

.grid-item {
  display: flex; /* enable flexbox layout for grid items */
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
}

.vertical-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list {
  font-family: Arial;
  font-size: 12px;
}

.container-main-area {
  position: relative;
  display: flex;
}

.content {
  padding: 20px;
}

.overlay {
  /* Set the element to take up the full height and width of the viewport */
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  
  /* Set the z-index value to a high number to ensure it overlays other elements */
  z-index: 9999;
  
  /* Add any other desired styles for the overlay element */
  /* ... */
}

.overlay-button-left {
  background-color: transparent;
  position: fixed;
  top: 50%;
  left: 4%;
  transform: translate(-50%, -50%);
  size: 10px;
  padding: 10px 10px;
  cursor: pointer; 
  /* Set the z-index value to a high number to ensure it overlays other elements */
  z-index: 9999;
}

.overlay-button-right {
  background-color: transparent;
  position: fixed;
  top: 50%;
  left: 96%;
  transform: translate(-50%, -50%);
  size: 10px;
  padding: 10px 10px;
  cursor: pointer;
   /* Set the z-index value to a high number to ensure it overlays other elements */
  z-index: 9999;
}

.result {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.back-home-button {
  display: flex;
  justify-content: left;
  margin: 0px;
}

.share-button {
  display: flex;
  justify-content: right;
  margin: 0px;
}

.overlay-top-left {
  margin: 30px;
  text-align: center;
  font-size: 30px;
  /* position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(-50%, -50%);
  border: none;
  size: 10px;
  padding: 10px 10px;
  cursor: pointer; */
}

.centerText {
  /* Set the text alignment to center */
  text-align: center;
}

.centerDiv {
  /* Center the element horizontally within the viewport */
  margin-left: auto;
  margin-right: auto;
  /* Center the element horizontally within the viewport */
  margin-top: auto;
  margin-bottom: auto;
}

/* Center the element horizontally within the viewport */
.centerDiv2 {
  display: flex;
  justify-content: center;
}

.screenSizeBattleReportImage {
  /* Set the element to take up the full width of the viewport */
  width: 100vw;
  
  /* Center the element horizontally within the viewport */
  margin-left: auto;
  margin-right: auto;
  
  /* Set a maximum width of 900px */
  max-width: 900px;
}

/*Targets screens with a maximum width of 767 pixels (which typically 
includes most mobile devices) and an orientation of landscape*/
@media only screen and (max-width: 900px) and (orientation: landscape) {
  .screenSizeBattleReportImage {
    /* Set the element to take up the full width of the viewport */
    height: 90vh;
    width: auto;
  }
}

.buttonBar {
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: auto;
  width: 100vw;
  max-width: 900px;
  align-items: center;
  grid-gap: 10px;
  align-content: center;
  margin: 0 auto;
  height: 100%;
}

.slider {
  accent-color: #0096d9;
  width: 35px;
  height: 25px;
  padding: 0px;
}

.feedbackButton {
  display: flex;
  justify-content: left;
  padding: 10px;
}
</style>
